import { useRouter } from 'next/router';
import React, {
  createContext,
  useReducer,
  useCallback,
  useContext,
} from 'react';

import { AuthContext } from 'contexts/authContext';
import { useRecaptchaContext } from 'contexts/RecaptchaContext';
import { IDonationProduct } from 'models/donationProduct';
import { Frequency } from 'models/frequency';
import { IShoppingCartItem } from 'models/shoppingCartItem';
import { fetcher } from 'services/httpRequestor';
import { ShoppingCartClient } from 'services/shoppingCartClient';

import reducer, {
  initialState,
  CartTypes,
  CartState,
  isBuyingMonthly,
} from './state/cartState';

interface IActions {
  goToCheckoutPage: (
    donationProduct: IDonationProduct,
    asAGuest: boolean,
    createNewAccountTabActive: boolean,
  ) => Promise<void>;
  fetchUserCart: () => void;
  addToCart: (donationCampaign: IDonationProduct) => Promise<void>;
  mergeCart: () => Promise<void>;
}
export const CartContext = createContext<{
  state: CartState;
  actions: IActions;
}>({
  state: initialState,
  actions: {
    fetchUserCart: () => null,
    addToCart: () => Promise.reject(),
    mergeCart: () => Promise.reject(),
    goToCheckoutPage: () => Promise.reject(),
  },
});

const CartContextProvider = ({
  serverInitialState: { userCart } = { userCart: undefined },
  children,
}: React.PropsWithChildren<any>) => {
  const { getRecaptchaToken } = useRecaptchaContext();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...{
      userCart,
      buyingMonthly: isBuyingMonthly(userCart),
    },
  });

  const fetchUserCart = useCallback(async () => {
    const shoppingCart = new ShoppingCartClient(fetcher());
    const { data } = await shoppingCart.getMyCart();
    dispatch({ type: CartTypes.LOAD_SUCCESS, payload: data });
  }, [dispatch]);

  const addToCart = useCallback(
    async (product: IDonationProduct) => {
      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        console.error('Recaptcha token is not valid');
        return;
      }
      const shoppingCart = new ShoppingCartClient(fetcher());
      const { status } = await shoppingCart.addDonationToCart(
        product,
        recaptchaToken,
      );

      if (status === 201) {
        await fetchUserCart();
      }

      // TODO: status 202, item is already in the cart.
    },
    [fetchUserCart, getRecaptchaToken],
  );

  const mergeCart = useCallback(async (): Promise<void> => {
    if (!state.userCart || !state.userCart.shopping_cart_items) {
      return;
    }

    const { shopping_cart_items } = state.userCart;

    await Promise.all([
      shopping_cart_items.map((item: IShoppingCartItem) => {
        return addToCart({
          custom_unit_amount: item.custom_unit_amount,
          donation_campaign_id: item.donation_campaign?.id,
          donation_day: item.donation_day,
          donation_option_id: item.donation_option?.id,
          donation_fund_id: item.donation_fund?.id,
          frequency: item.frequency?.code as Frequency,
          quantity: item.quantity,
          delivery_option: item.delivery_option,
          recipient_first_name: item.recipient_first_name,
          recipient_last_name: item.recipient_last_name,
          recipient_email: item.recipient_email,
          recipient_message: item.recipient_message,
          delivery_dt: item.delivery_dt,
          recipient_custom_to: item.recipient_custom_to,
          recipient_custom_from: item.recipient_custom_from,
          // TODO: Add this once we can add the ticket book in foundation
          // ticket_book_id: item.ticket_book?.id,
        });
      }),
    ]);

    await fetchUserCart();
  }, [fetchUserCart, addToCart, state]);

  const router = useRouter();
  const { actions: authActions, state: authState } = useContext(AuthContext);

  const goToCheckoutPage = useCallback(
    async (
      donationProduct: IDonationProduct,
      asAGuest = false,
      createNewAccountTabActive = false,
    ) => {
      const { user } = authState;
      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        console.error('Recaptcha token is not valid');
        return;
      }

      if (!user) {
        await authActions.loginAnonymous();
      } else if (user?.isAnonymous) {
        // Reset the anonymous user to not duplicate
        // customer with the same uid
        await authActions.logout();
        await authActions.loginAnonymous();
      }

      await addToCart(donationProduct);

      router
        .push({
          pathname: '/checkout',
          query: {
            asAGuest,
            createNewAccountTabActive,
            campaignSlug: router.query.slug,
          },
        })
        .then(() => window.scrollTo(0, 0));
    },
    [authState, getRecaptchaToken, addToCart, router, authActions],
  );

  const actions: IActions = {
    fetchUserCart,
    addToCart,
    mergeCart,
    goToCheckoutPage,
  };

  return (
    <CartContext.Provider value={{ state, actions }}>
      {children}
    </CartContext.Provider>
  );
};

const useCart = (): IActions & CartState => {
  const { state, actions } = useContext(CartContext);
  return Object.assign(actions, state);
};

export { useCart };

export default CartContextProvider;
