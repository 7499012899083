import { IPaginatedResponse } from 'models/paginatedResponse';
import IStack9SearchBodyFormat from 'models/stack9SearchBodyFormat';

import HttpRequestor, { BaseResponse } from './httpRequestor';

interface IGetAllOptions {
  query: any;
  [key: string]: any;
}

export class EntityService<T> {
  constructor(public httpRequestor: HttpRequestor, public entity: string) {}

  public getAllWithPagination(
    pageIndex = 0,
    limit = 9,
    options?: any,
  ): Promise<BaseResponse<IPaginatedResponse<T>>> {
    return this.httpRequestor.get(`/${this.entity}`, {
      ...options,
      query: {
        ...(options?.query || {}),
        limit,
        page: pageIndex.toString(),
      },
    });
  }

  public async getAll(options?: IGetAllOptions): Promise<BaseResponse<T[]>> {
    return this.httpRequestor.get(`/${this.entity}`, options);
  }

  public async create(
    body?: any,
    options?: any,
  ): Promise<BaseResponse<{ id: number; entity: T }>> {
    return this.httpRequestor.post(`/${this.entity}`, body, options);
  }

  public async update(
    id: string | number,
    body: any,
    options?: any,
  ): Promise<BaseResponse<T>> {
    return this.httpRequestor.put(`/${this.entity}/${id}`, body, options);
  }

  public async delete(
    id: string | number,
    options?: any,
  ): Promise<BaseResponse<T>> {
    return this.httpRequestor.delete(`/${this.entity}/${id}`, options);
  }

  public async getOneByID(
    id: string | number,
    options?: any,
  ): Promise<BaseResponse<T>> {
    return this.httpRequestor.get(`/${this.entity}/${id}`, options);
  }

  async search(
    bodySearch?: IStack9SearchBodyFormat,
    options?: any,
  ): Promise<BaseResponse<T[]>> {
    const finalBodySearch = {
      ...bodySearch,
      $where: {
        ...(bodySearch?.$where || {}),
        _is_deleted: bodySearch?.$where?._is_deleted || false,
      },
    };

    const response = await this.httpRequestor.post<T[]>(
      `/${this.entity}/search`,
      finalBodySearch,
      options,
    );

    return response;
  }

  async searchWithPagination(
    bodySearch: IStack9SearchBodyFormat,
    limit = 9,
    pageIndex = 0,
    options?: any,
  ): Promise<BaseResponse<IPaginatedResponse<T[]>>> {
    const finalBodySearch = {
      ...bodySearch,
      $where: {
        ...(bodySearch?.$where || {}),
        _is_deleted: bodySearch?.$where?._is_deleted || false,
      },
    };
    const value: any = this.search(finalBodySearch, {
      ...(options || {}),
      query: {
        ...(options?.query || {}),
        limit,
        page: pageIndex.toString(),
      },
    });

    return value;
  }

  async searchOne(
    bodySearch: IStack9SearchBodyFormat,
    options?: any,
  ): Promise<T | null> {
    const {
      data: [entity],
    } = await this.search(bodySearch, options);
    return entity || null;
  }

  // Filter using the properties equals to something
  // e.g: { username: 'kenji', 'email': 'shiroma' }
  //  {
  //    username: {
  //      $eq: 'kenji'
  //    }
  //  }
  public async searchByEq(
    body: any,
    options?: any,
  ): Promise<BaseResponse<T[]>> {
    const searchBody = Object.keys(body).reduce((accummulate, currentKey) => {
      return {
        ...accummulate,
        [currentKey]: {
          $eq: body[currentKey],
        },
      };
    }, {});
    return this.httpRequestor.post(
      `/${this.entity}/search`,
      searchBody,
      options,
    );
  }

  // Abstraction of searchBy to return just one object or null
  public async searchOneByEq(body: any, options?: any): Promise<T | null> {
    const result = await this.searchByEq(body, options);
    return result?.data?.length ? result.data[0] : null;
  }
}
