import { Frequency } from 'models/frequency';
import { IShoppingCart } from 'models/shoppingCart';
import { IShoppingCartItem } from 'models/shoppingCartItem';

import { Action } from './appState';

export interface CartState {
  userCart: IShoppingCart | undefined;
  buyingMonthly?: boolean;
  error?: string;
}

export enum CartTypes {
  LOAD_SUCCESS = 'LOAD_SUCCESS', // TOOD: I think just load and checkouts are being used.
}

const initialState: CartState = {
  userCart: undefined,
  buyingMonthly: undefined,
  error: undefined,
};

export const isBuyingMonthly = (userCart?: IShoppingCart) => {
  return userCart?.shopping_cart_items?.some(
    (cartItem: IShoppingCartItem) =>
      cartItem.frequency.code === Frequency.Monthly,
  );
};

const reducer = (state: CartState, action: Action<CartTypes>): CartState => {
  switch (action.type) {
    case CartTypes.LOAD_SUCCESS: {
      const userCart = action.payload;
      return {
        ...state,
        ...{
          userCart: action.payload,
          buyingMonthly: isBuyingMonthly(userCart),
        },
      };
    }

    default:
      throw new Error('Action not implemented');
  }
};

export { initialState };

export default reducer;
