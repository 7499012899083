import { ICheckoutCart } from 'models/checkoutCart';
import { IDonationProduct } from 'models/donationProduct';
import { ISalesOrder } from 'models/salesOrder';
import { IShoppingCart } from 'models/shoppingCart';
import { ShoppingCartItemService } from 'services/shoppingCartItemService';

import HttpRequestor, { BaseResponse } from './httpRequestor';

export class ShoppingCartClient {
  public shoppingCartItemService: ShoppingCartItemService;

  public httpRequestor: HttpRequestor;

  constructor(httpRequestor: HttpRequestor) {
    this.httpRequestor = httpRequestor;
    this.shoppingCartItemService = new ShoppingCartItemService(httpRequestor);
  }

  async addToCart(
    props: any,
    recaptchaToken: string,
  ): Promise<BaseResponse<{ id: number }>> {
    return this.shoppingCartItemService.create(props, {
      headers: {
        'x-recaptcha-token': recaptchaToken,
      },
    });
  }

  async addDonationToCart(
    product: IDonationProduct,
    recaptchaToken: string,
  ): Promise<BaseResponse<{ id: number }>> {
    return this.addToCart(product, recaptchaToken);
  }

  async getMyCart(): Promise<BaseResponse<IShoppingCart>> {
    return this.httpRequestor.get<IShoppingCart>('/shopping_cart/me');
  }

  async checkoutMyCart({
    paymentType,
    singleUseToken,
    guestDetailFormProps,
    orderPayPal,
    recaptchaToken,
  }: ICheckoutCart): Promise<BaseResponse<ISalesOrder>> {
    return this.httpRequestor.post<ISalesOrder>(
      '/sales_order',
      {
        paymentType,
        singleUseToken,
        ...(guestDetailFormProps || {}),
        orderPayPal,
      },
      {
        headers: {
          'x-recaptcha-token': recaptchaToken,
        },
      },
    );
  }
}
