import { IShoppingCartItem } from 'models/shoppingCartItem';

import { EntityService } from './entityService';
import HttpRequestor from './httpRequestor';

export class ShoppingCartItemService extends EntityService<IShoppingCartItem> {
  constructor(httpRequestor: HttpRequestor) {
    super(httpRequestor, 'shopping_cart_item');
  }
  // Write down the specifics when needed
}
